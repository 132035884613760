import axios from 'axios'
import { API_ROUTE, COOKIE_DOMAIN } from '@/lib/constants'
import Cookies from 'js-cookie'

export async function getStripeUrl(orgId) {
  // console.log('getStripeUrl: ', orgId)
  // console.log(`${API_ROUTE}/orgs/${orgId}/stripe`)
  try {
    const response = await axios.get(`${API_ROUTE}/orgs/${orgId}/stripe`, {
      withCredentials: true, // to send cookies
    })
    // console.log('getStripeUrl response: ', response)
    return response.data
  } catch (e) {
    // console.log('getStripeUrl error: ', e)
    console.error(e)
    throw e
  }
}

export const freePlan = {
  name: 'Free',
  description:
    'The free plan is limited to 3 free screens. Upgrade to the PRO plan for unlimited screens and organizations.',
  stripePriceId: '',
}

export const proPlan = {
  name: 'PRO',
  description: 'The PRO plan has access to unlimited screens.',
  stripePriceId: process.env.STRIPE_PRO_MONTHLY_PLAN_ID || '',
}

export async function getOrgSubscriptionPlan(orgId) {
  // console.log('getOrgSubscriptionPlan: ', orgId)
  try {
    const response = await axios.get(`${API_ROUTE}/orgs/${orgId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getOrgSubscriptionPlan response: ', response)

    const orgData = response.data.data
    // console.log('org: ', orgData.org.id)
    // console.log(
    //   new Date(orgData.org.stripe_current_period_end).getTime() + 86_400_000
    // )

    // Check if org is on a pro plan.
    const isPro =
      orgData.org.stripe_price_id &&
      new Date(orgData.org.stripe_current_period_end).getTime() + 86_400_000 >
        Date.now()
    // console.log('isPro: ', isPro)

    const plan = isPro ? proPlan : freePlan
    // console.log('plan: ', plan)

    return {
      ...plan,
      ...orgData,
      stripeSubscriptionId: orgData.org.stripe_subscription_id,
      stripeCurrentPeriodEnd: new Date(
        orgData.org.stripe_current_period_end
      ).getTime(),
      orgId: orgData.org.id,
      isPro,
    }
  } catch (e) {
    console.error(e)
    return {
      ...freePlan,
      stripeSubscriptionId: '',
      orgId: '',
      data: '',
      stripeCustomerId: '',
      stripeCurrentPeriodEnd: 0,
      isPro: false,
    }
  }
}

export async function swapOrganization(userId, orgId, noReload) {
  // console.log('swapOrganization: ', userId, orgId)
  try {
    await axios.patch(
      `${API_ROUTE}/users/${userId}/active-org`,
      {
        org_id: orgId,
      },
      {
        withCredentials: true, // to send cookies
      }
    )
    // console.log('swapOrganization response: ', response)

    if (!noReload) {
      window.location.reload()
    }
  } catch (e) {
    // console.log('swapOrganization error: ', e)
    console.error(e)
    throw e
  }
}

export async function createOrganization(name) {
  // console.log('create new organization: ', name)
  try {
    await axios.post(
      `${API_ROUTE}/orgs`,
      {
        name: name,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('createOrganization response: ', response)

    window.location.reload()
  } catch (e) {
    // console.log('createOrganization error: ', e)
    console.error(e)
    throw e
  }
}

export async function patchMe(username) {
  // console.log('patch username: ', username)
  try {
    await axios.patch(
      `${API_ROUTE}/users/me`,
      {
        username: username,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('patchMe response: ', response)

    window.location.reload()
  } catch (e) {
    // console.log('patchMe error: ', e)
    console.error(e)
    throw e
  }
}

export async function patchOrganization(name, orgId, proofOfPlay) {
  // console.log('patch organization: ', name, orgId, proofOfPlay)
  try {
    await axios.patch(
      `${API_ROUTE}/orgs/${orgId}`,
      {
        name: name,
        proof_of_play: proofOfPlay,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('patchOrganization response: ', response)

    window.location.reload()
  } catch (e) {
    // console.log('patchOrganization error: ', e)
    console.error(e)
    throw e
  }
}

export async function patchOrganizationName(name, orgId) {
  // console.log('patch organization: ', name, orgId)
  try {
    await axios.patch(
      `${API_ROUTE}/orgs/${orgId}`,
      {
        name: name,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('patchOrganization response: ', response)

    window.location.reload()
  } catch (e) {
    // console.log('patchOrganization error: ', e)
    console.error(e)
    throw e
  }
}

export async function inviteUser(email, roleId, orgId) {
  // console.log('create new invitation: ', email, roleId)
  try {
    await axios.post(
      `${API_ROUTE}/orgs/${orgId}/users/invitations`,
      {
        email: email,
        role: roleId,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('inviteUser response: ', response)
  } catch (e) {
    // console.log('inviteUser error: ', e)
    console.error(e)
    throw e
  }
}

export async function createRole(name, permissions) {
  // console.log('create new role: ', name, permissions)
  // console.log(permissions)
  try {
    await axios.post(
      `${API_ROUTE}/roles`,
      {
        name: name,
        permissions: permissions,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('createRole response: ', response)
  } catch (e) {
    // console.log('createRole error: ', e)
    console.error(e)
    throw e
  }
}

export async function updateRole(name, permissions, roleId) {
  // console.log('update role: ', name, permissions, roleId)
  // console.log('updateRole,permissions', permissions)
  try {
    await axios.patch(
      `${API_ROUTE}/roles/${roleId}`,
      {
        name: name,
        permissions: permissions,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('updateRole response: ', response)
  } catch (e) {
    // console.log('updateRole error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteRole(roleId) {
  // console.log('delete role: ', roleId)
  try {
    await axios.delete(`${API_ROUTE}/roles/${roleId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('deleteRole response: ', response)
  } catch (e) {
    // console.log('deleteRole error: ', e)
    console.error(e)
    throw e
  }
}

export async function updateUserRole(roleId, userId) {
  // console.log('update user role: ', roleId)
  try {
    await axios.patch(
      `${API_ROUTE}/users/${userId}/role`,
      {
        role_id: roleId,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('updateUserRole response: ', response)
  } catch (e) {
    // console.log('updateUserRole error: ', e)
    console.error(e)
    throw e
  }
}

export async function acceptInvite(inviteId, orgId) {
  // console.log('accept invite: ', inviteId, orgId)
  try {
    await axios.get(
      `${API_ROUTE}/orgs/${orgId}/users/invitations/${inviteId}/accept`,
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('acceptInvite response: ', response)

    window.location.reload()
  } catch (e) {
    // console.log('acceptInvite error: ', e)
    console.error(e)
    throw e
  }
}

export async function declineInvite(inviteId, orgId) {
  // console.log('accept invite: ', inviteId, orgId)
  try {
    await axios.get(
      `${API_ROUTE}/orgs/${orgId}/users/invitations/${inviteId}/decline`,
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('declineInvite response: ', response)

    window.location.reload()
  } catch (e) {
    // console.log('declineInvite error: ', e)
    console.error(e)
    throw e
  }
}

export async function getFlashMessage() {
  // console.log('getFlashMessage: ')
  try {
    const response = await axios.get(`${API_ROUTE}/flash`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getFlashMessage response: ', response)
    return response.data
  } catch (e) {
    // console.log('getFlashMessage error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteUserFromOrganization(userId, orgId) {
  try {
    await axios.delete(`${API_ROUTE}/orgs/${orgId}/users/${userId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('deleteUserFromOrganization response: ', response)
  } catch (e) {
    // console.log('deleteUserFromOrganization error: ', e)
    console.error(e)
    throw e
  }
}

export async function getFoldersAndFiles() {
  // console.log('getFoldersAndFiles: ')
  try {
    const response = await axios.get(`${API_ROUTE}/folders`, {
      withCredentials: true, // to send cookies
    })
    // console.log('getFoldersAndFiles response: ', response)
    return response.data
  } catch (e) {
    // console.log('getFoldersAndFiles error: ', e)
    console.error(e)
    throw e
  }
}

export async function createFolder(name, parentId) {
  // console.log('create new folder: ', name, parentId)
  try {
    await axios.post(
      `${API_ROUTE}/folders`,
      {
        name: name,
        parent_id: parentId,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('createFolder response: ', response)
  } catch (e) {
    // console.log('createFolder error: ', e)
    console.error(e)
    throw e
  }
}

export async function uploadFile(file, parentId) {
  // console.log('Uploading file: ', file.name, 'to folder:', parentId)

  // Prepare the form data
  const formData = new FormData()
  formData.append('file', file)

  try {
    const response = await axios.post(
      `${API_ROUTE}/files/${parentId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true, // to send cookies
      }
    )

    // console.log('uploadFile response: ', response)
    return response.data // or however you want to handle the response
  } catch (error) {
    console.error('Error uploading file: ', error)
    // Handle error appropriately
    throw error
  }
}

export async function deleteFolder(folderId) {
  // console.log('delete folder: ', folderId)
  try {
    await axios.delete(`${API_ROUTE}/folders/${folderId}`, {
      withCredentials: true, // to send cookies
    })
    // console.log('deleteFolder response: ', response)
  } catch (e) {
    // console.log('deleteFolder error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteFile(fileId) {
  // console.log('delete file: ', fileId)
  try {
    await axios.delete(`${API_ROUTE}/files/${fileId}`, {
      withCredentials: true, // to send cookies
    })
    // console.log('deleteFile response: ', response)
  } catch (e) {
    // console.log('deleteFile error: ', e)
    console.error(e)
    throw e
  }
}

export async function getDevices() {
  // console.log('get devices: ')
  try {
    const response = await axios.get(`${API_ROUTE}/devices`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getDevices response: ', response)
    return response.data
  } catch (e) {
    // console.log('getDevices error: ', e)
    console.error(e)
    throw e
  }
}

export async function enrollDevice(name, code) {
  // console.log('enroll device: ', name, code)
  try {
    await axios.post(
      `${API_ROUTE}/devices`,
      {
        name: name,
        code: code.toUpperCase(),
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('enrollDevice response: ', response)
  } catch (e) {
    // console.log('enrollDevice error: ', e)
    console.error(e)
    throw e
  }
}

export async function getDeviceGroups() {
  // console.log('get device groups: ')
  try {
    const response = await axios.get(`${API_ROUTE}/device-groups`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getDeviceGroups response: ', response)
    return response.data
  } catch (e) {
    // console.log('getDeviceGroups error: ', e)
    console.error(e)
    throw e
  }
}

export async function getDeviceGroup(groupId) {
  // console.log('get device group: ')
  try {
    const response = await axios.get(`${API_ROUTE}/device-groups/${groupId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getDeviceGroup response: ', response)
    return response
  } catch (e) {
    // console.log('getDeviceGroup error: ', e)
    console.error(e)
    throw e
  }
}

export async function patchDeviceGroup(name, groupId, devices) {
  // console.log('patch device group: ', name, groupId, devices)
  try {
    await axios.patch(
      `${API_ROUTE}/device-groups/${groupId}`,
      {
        name: name,
        devices: devices,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('patch device group response: ', response)
  } catch (e) {
    // console.log('patch device group error: ', e)
    console.error(e)
    throw e
  }
}

export async function renameDeviceGroup(name, groupId) {
  // console.log('rename device group: ', name, groupId)
  try {
    await axios.patch(
      `${API_ROUTE}/device-groups/${groupId}/rename`,
      {
        name: name,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('rename device group response: ', response)
    window.location.reload()
  } catch (e) {
    // console.log('rename device group error: ', e)
    console.error(e)
    throw e
  }
}

export async function createDeviceGroup(name) {
  // console.log('create device group: ', name)
  try {
    await axios.post(
      `${API_ROUTE}/device-groups`,
      {
        name: name,
        devices: [],
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('create device group response: ', response)
  } catch (e) {
    // console.log('create device group error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteGroup(groupId) {
  // console.log('delete group: ', groupId)
  try {
    await axios.delete(`${API_ROUTE}/device-groups/${groupId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('delete group response: ', response)
  } catch (e) {
    // console.log('delete group error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteDevice(deviceId) {
  // console.log('delete device: ', deviceId)
  try {
    await axios.delete(`${API_ROUTE}/devices/${deviceId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('delete device response: ', response)
  } catch (e) {
    // console.log('delete device error: ', e)
    console.error(e)
    throw e
  }
}

export async function createPlaylist(name, files) {
  // console.log('create new playlist: ', name, files)
  try {
    const response = await axios.post(
      `${API_ROUTE}/playlists`,
      {
        name: name,
        files: files,
        apps: [],
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    return response.data

    // // console.log("createPlaylist response: ", response);
  } catch (e) {
    // console.log('createPlaylist error: ', e)
    console.error(e)
    throw e
  }
}

export async function getPlaylists() {
  // console.log('get playlists: ')
  try {
    const response = await axios.get(`${API_ROUTE}/playlists`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getPlaylists response: ', response)
    return response.data
  } catch (e) {
    // console.log('getPlaylists error: ', e)
    console.error(e)
    throw e
  }
}

export async function patchPlaylist(name, files, playlistId) {
  // console.log('patch playlist: ', name, files, playlistId)
  try {
    await axios.patch(
      `${API_ROUTE}/playlists/${playlistId}`,
      {
        name: name,
        files: files,
        apps: [],
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('patchPlaylist response: ', response)
  } catch (e) {
    // console.log('patchPlaylist error: ', e)
    console.error(e)
    throw e
  }
}

export async function deletePlaylist(playlistId) {
  // console.log('delete playlist: ', playlistId)
  try {
    await axios.delete(`${API_ROUTE}/playlists/${playlistId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('delete playlist response: ', response)
  } catch (e) {
    // console.log('delete playlist error: ', e)
    console.error(e)
    throw e
  }
}

export async function getSchedules() {
  // console.log('get schedules: ')
  try {
    const response = await axios.get(`${API_ROUTE}/schedules`, {
      withCredentials: true, // to send cookies
    })

    // console.log('get schedules response: ', response)
    return response.data
  } catch (e) {
    // console.log('get schedules error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteSchedule(scheduleId) {
  // console.log('delete schedule: ', scheduleId)
  try {
    await axios.delete(`${API_ROUTE}/schedules/${scheduleId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('delete schedule response: ', response)
  } catch (e) {
    // console.log('delete schedule error: ', e)
    console.error(e)
    throw e
  }
}

export async function createSchedule(schedule) {
  // console.log(
  // 'create new schedule: ',
  //   schedule.name,
  //   schedule.playlistId,
  //   schedule.groupId,
  //   schedule.start,
  //   schedule.end
  // )
  try {
    const response = await axios.post(
      `${API_ROUTE}/schedules`,
      {
        name: schedule.name,
        playlist_id: schedule.playlistId,
        group_id: schedule.groupId,
        start_date: schedule.start,
        end_date: schedule.end,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    return response.data
  } catch (e) {
    // console.log('create schedule error: ', e)
    console.error(e)
    throw e
  }
}

export async function patchSchedule(schedule, scheduleId) {
  // console.log(
  // 'create new schedule: ',
  //   scheduleId,
  //   schedule.name,
  //   schedule.playlistId,
  //   schedule.groupId,
  //   schedule.start,
  //   schedule.end
  // )
  try {
    const response = await axios.patch(
      `${API_ROUTE}/schedules/${scheduleId}`,
      {
        name: schedule.name,
        playlist_id: schedule.playlistId,
        group_id: schedule.groupId,
        start_date: schedule.start,
        end_date: schedule.end,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    return response.data
  } catch (e) {
    // console.log('create schedule error: ', e)
    console.error(e)
    throw e
  }
}

export async function getFiles() {
  // console.log('get files: ')
  try {
    const response = await axios.get(`${API_ROUTE}/files`, {
      withCredentials: true, // to send cookies
    })

    // console.log('get files response: ', response)
    return response.data
  } catch (e) {
    // console.log('get files error: ', e)
    console.error(e)
    throw e
  }
}

export async function getDevice(deviceId) {
  // console.log('get device: ')
  try {
    const response = await axios.get(`${API_ROUTE}/devices/${deviceId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getDevice response: ', response)
    return response
  } catch (e) {
    // console.log('getDevice error: ', e)
    console.error(e)
    throw e
  }
}

export async function getScreenshot(deviceId) {
  // console.log('get screenshot: ')
  try {
    const response = await axios.get(
      `${API_ROUTE}/devices/${deviceId}/screenshot`,
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('get screenshot response: ', response)
    return response
  } catch (e) {
    // console.log('get screenshot error: ', e)
    console.error(e)
    throw e
  }
}

export async function getEmptyStorage(deviceId) {
  // console.log('get empty storage: ')
  try {
    const response = await axios.get(
      `${API_ROUTE}/devices/${deviceId}/empty-storage`,
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('get empty storage response: ', response)
    return response
  } catch (e) {
    // console.log('get empty storage error: ', e)
    console.error(e)
    throw e
  }
}

export async function getReboot(deviceId) {
  // console.log('get reboot: ')
  try {
    const response = await axios.get(
      `${API_ROUTE}/devices/${deviceId}/reboot`,
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('get reboot response: ', response)
    return response
  } catch (e) {
    // console.log('get reboot error: ', e)
    console.error(e)
    throw e
  }
}

export async function getSilentUpdate(deviceId) {
  try {
    const response = await axios.get(
      `${API_ROUTE}/devices/${deviceId}/updateapp`,
      {
        withCredentials: true, // to send cookies
      }
    )

    return response
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function patchDevice(
  name,
  deviceId,
  latitude,
  longitude,
  screenOrientation
) {
  // console.log(
  // 'patch device: ',
  //   name,
  //   deviceId,
  //   latitude,
  //   longitude,
  //   screenOrientation
  // )
  try {
    await axios.patch(
      `${API_ROUTE}/devices/${deviceId}`,
      {
        name: name,
        latitude: latitude,
        longitude: longitude,
        screen_orientation: screenOrientation,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('patch device response: ', response)
  } catch (e) {
    // console.log('patch device error: ', e)
    console.error(e)
    throw e
  }
}

export async function getSyncRules() {
  // console.log('get sync rules: ')
  try {
    const response = await axios.get(`${API_ROUTE}/sync`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getSyncRules response: ', response)
    return response.data
  } catch (e) {
    // console.log('getSyncRules error: ', e)
    console.error(e)
    throw e
  }
}

export async function getSyncRule(syncRuleId) {
  // console.log('get sync rule: ')
  try {
    const response = await axios.get(`${API_ROUTE}/sync/${syncRuleId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getSyncRule response: ', response)
    return response
  } catch (e) {
    // console.log('getSyncRule error: ', e)
    console.error(e)
    throw e
  }
}

export async function patchSyncRule(
  name,
  syncRuleId,
  restart,
  groupId,
  devices
) {
  // console.log('patch sync rule: ', name, syncRuleId, restart, groupId, devices)
  try {
    await axios.patch(
      `${API_ROUTE}/sync/${syncRuleId}`,
      {
        name: name,
        devices: devices,
        group_id: groupId,
        restart: restart,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('patch sync rule response: ', response)
  } catch (e) {
    // console.log('patch sync rule error: ', e)
    console.error(e)
    throw e
  }
}

export async function createSyncRule(name, restart, groupId, devices) {
  // console.log('create sync rule: ', name, restart)
  try {
    await axios.post(
      `${API_ROUTE}/sync`,
      {
        name: name,
        group_id: groupId,
        devices: devices,
        restart: restart,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('create sync rule response: ', response)
  } catch (e) {
    // console.log('create sync rule error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteSyncRule(syncRuleId) {
  // console.log('delete sync rule: ', syncRuleId)
  try {
    await axios.delete(`${API_ROUTE}/sync/${syncRuleId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('delete sync rule response: ', response)
  } catch (e) {
    // console.log('delete sync rule error: ', e)
    console.error(e)
    throw e
  }
}

export async function putNewApiKey() {
  // console.log('putNewApiKey: ')
  try {
    const response = await axios.put(
      `${API_ROUTE}/users/api`,
      {},
      {
        // Empty data object
        withCredentials: true,
      }
    )
    // console.log('putNewApiKey response: ', response)
    return response.data
  } catch (e) {
    // console.log('putNewApiKey error: ', e)
    console.error(e)
    throw e
  }
}

export async function getApiKeys() {
  // console.log('get api keys: ')
  try {
    const response = await axios.get(`${API_ROUTE}/users/api`, {
      withCredentials: true, // to send cookies
    })

    // console.log('get api keys response: ', response)
    return response.data
  } catch (e) {
    // console.log('get api keys error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteApiKey(apiKeyId) {
  // console.log('delete api key: ', apiKeyId)
  try {
    await axios.delete(`${API_ROUTE}/users/api/${apiKeyId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('delete api key response: ', response)
  } catch (e) {
    // console.log('delete api key error: ', e)
    console.error(e)
    throw e
  }
}

export async function patchFolderName(folderId, name) {
  // console.log('patch folder name: ', name, folderId)
  try {
    await axios.patch(
      `${API_ROUTE}/folders/${folderId}`,
      {
        name: name,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('patch folder name response: ', response)
  } catch (e) {
    // console.log('patch folder name error: ', e)
    console.error(e)
    throw e
  }
}

export async function getApps() {
  // console.log('get apps: ')
  try {
    const response = await axios.get(`${API_ROUTE}/apps`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getApps response: ', response)
    return response.data
  } catch (e) {
    // console.log('getApps error: ', e)
    console.error(e)
    throw e
  }
}
export async function createApp(name, url) {
  // console.log('create new app: ', name, url)
  try {
    await axios.post(
      `${API_ROUTE}/apps`,
      {
        name: name,
        url: url,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('createApp response: ', response)
  } catch (e) {
    // console.log('createApp error: ', e)
    console.error(e)
    throw e
  }
}

export async function patchPlaylistApps(name, playlistId, apps) {
  // console.log('patch playlist with apps: ', name, playlistId, apps)
  try {
    await axios.patch(
      `${API_ROUTE}/playlists/${playlistId}/apps`,
      {
        name: name,
        files: [],
        apps: apps,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('patchPlaylist with apps response: ', response)
  } catch (e) {
    // console.log('patchPlaylist with apps error: ', e)
    console.error(e)
    throw e
  }
}

export async function createPlaylistApps(name, apps) {
  // console.log('create new playlist apps: ', name, apps)
  try {
    const response = await axios.post(
      `${API_ROUTE}/playlists/apps`,
      {
        name: name,
        files: [],
        apps: apps,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    return response.data

    // // console.log("createPlaylist response: ", response);
  } catch (e) {
    // console.log('createPlaylist apps error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteApp(appId) {
  // console.log('delete app: ', appId)
  try {
    await axios.delete(`${API_ROUTE}/apps/${appId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('delete app response: ', response)
  } catch (e) {
    // console.log('delete app error: ', e)
    console.error(e)
    throw e
  }
}

export async function getMetricsDevice() {
  // console.log('get metrics device: ')
  try {
    const response = await axios.get(`${API_ROUTE}/metrics/device`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getMetricsDevice response: ', response)
    return response.data
  } catch (e) {
    // console.log('getMetricsDevice error: ', e)
    console.error(e)
    throw e
  }
}

export async function getMetricsEnrollments() {
  // console.log('get metrics enrollments: ')
  try {
    const response = await axios.get(`${API_ROUTE}/metrics/enrollments`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getMetricsEnrollments response: ', response)
    return response.data
  } catch (e) {
    // console.log('getMetricsEnrollments error: ', e)
    console.error(e)
    throw e
  }
}

export async function getMetricsProofOfPlay() {
  // console.log('get metrics proof of play')
  try {
    const response = await axios.get(`${API_ROUTE}/metrics/proof-of-play`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getMetricsProofOfPlay response: ', response)
    return response.data
  } catch (e) {
    // console.log('getMetricsProofOfPlay error: ', e)
    console.error(e)
    throw e
  }
}

export async function checkEmail(email) {
  // console.log('checkEmail: ', email)
  try {
    const response = await axios.post(`${API_ROUTE}/auth/check-email`, {
      email: email,
    })
    // console.log('checkEmail response: ', response)
    return response.data
  } catch (e) {
    // console.log('checkEmail error: ', e)
    console.error(e)
    throw e
  }
}

export async function loginEmail(email, password) {
  // console.log('loginEmail: ', email, password)
  try {
    const response = await axios.post(`${API_ROUTE}/auth/login`, {
      email: email,
      password: password,
    })
    // console.log('loginEmail response: ', response)
    if (response.data && response.data?.session_id !== undefined) {
      // set session_id cookie
      Cookies.set('session_id', response.data.session_id, {
        domain: COOKIE_DOMAIN,
        sameSite: 'None',
        Secure: true,
        expires: 1,
        path: '/',
      })
      window.location.href = '/dashboard'
    }
    return response.data
  } catch (e) {
    // console.log('loginEmail error: ', e)
    console.error(e)
    throw e
  }
}

export async function registerEmail(email, password, name, token) {
  // console.log('registerEmail: ', email, password, name, token)
  try {
    const response = await axios.post(`${API_ROUTE}/auth/register`, {
      email: email,
      password: password,
      name: name,
      cf_token: token,
    })
    // console.log('registerEmail response: ', response)
    return response.data
  } catch (e) {
    // console.log('registerEmail error: ', e)
    console.error(e)
    throw e
  }
}

export async function verifyEmailCode(code) {
  // console.log('verifyEmailCode: ', code)
  try {
    const response = await axios.get(
      `${API_ROUTE}/auth/verify/email?code=${code}`
    )
    // console.log('verifyEmailCode response: ', response)
    if (response.data && response.data?.session_id !== undefined) {
      // set session_id cookie
      Cookies.set('session_id', response.data.session_id, {
        domain: COOKIE_DOMAIN,
        sameSite: 'None',
        Secure: true,
        expires: 1,
        path: '/',
      })
      window.location.href = '/dashboard'
    }
    return response.data
  } catch (e) {
    // console.log('verifyEmailCode error: ', e)
    console.error(e)
    throw e
  }
}

export async function forgotPassword(email, token) {
  // console.log('forgotPassword: ', email)
  try {
    const response = await axios.post(`${API_ROUTE}/auth/forgot-password`, {
      email: email,
      cf_token: token,
    })
    // console.log('forgotPassword response: ', response)
    return response.data
  } catch (e) {
    // console.log('forgotPassword error: ', e)
    console.error(e)
    throw e
  }
}

export async function resetPassword(password, token) {
  // console.log('resetPassword: ', password, token)
  try {
    const response = await axios.post(`${API_ROUTE}/auth/reset-password`, {
      password: password,
      token: token,
    })
    // console.log('resetPassword response: ', response)
    return response.data
  } catch (e) {
    // console.log('resetPassword error: ', e)
    console.error(e)
    throw e
  }
}

export async function createAlert(
  name,
  alertType,
  notifyAfter,
  devices,
  emails
) {
  // console.log('create alert:', name, alertType, notifyAfter, devices, emails)
  try {
    await axios.post(
      `${API_ROUTE}/alerts`,
      {
        name: name,
        alert_type: alertType,
        notify_after: notifyAfter,
        devices: devices,
        emails: emails,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('create alert rule response: ', response)
  } catch (e) {
    // console.log('create alert rule error: ', e)
    console.error(e)
    throw e
  }
}

export async function getAlerts() {
  // console.log('get alert rules: ')
  try {
    const response = await axios.get(`${API_ROUTE}/alerts`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getAlerts response: ', response)
    return response.data
  } catch (e) {
    // console.log('getAlerts error: ', e)
    console.error(e)
    throw e
  }
}

export async function getAlert(alertId) {
  // console.log('get sync rule: ')
  try {
    const response = await axios.get(`${API_ROUTE}/alerts/${alertId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getAlert response: ', response)
    return response
  } catch (e) {
    // console.log('getAlert error: ', e)
    console.error(e)
    throw e
  }
}

export async function patchAlert(
  alertId,
  name,
  alertType,
  notifyAfter,
  devices,
  emails
) {
  // console.log(
  // 'patch alert rule: ',
  //   alertId,
  //   name,
  //   alertType,
  //   notifyAfter,
  //   devices,
  //   emails
  // )
  try {
    await axios.patch(
      `${API_ROUTE}/alerts/${alertId}`,
      {
        name: name,
        alert_type: alertType,
        notify_after: notifyAfter,
        devices: devices,
        emails: emails,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('patch alert rule response: ', response)
  } catch (e) {
    // console.log('patch alert rule error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteAlert(alertId) {
  // console.log('delete alert rule: ', alertId)
  try {
    await axios.delete(`${API_ROUTE}/alerts/${alertId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('delete alert rule response: ', response)
  } catch (e) {
    // console.log('delete alert rule error: ', e)
    console.error(e)
    throw e
  }
}

export async function getMe() {
  // console.log('get me')
  try {
    const response = await axios.get(`${API_ROUTE}/users/me`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getMe response: ', response)
    return response.data
  } catch (e) {
    // console.log('getMe error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteOrg(orgId) {
  // console.log('delete org: ', orgId)

  try {
    await axios.delete(`${API_ROUTE}/orgs/${orgId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('deleteOrg response: ', response)
  } catch (e) {
    // console.log('deleteOrg error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteMe() {
  // console.log('delete me')

  try {
    await axios.delete(`${API_ROUTE}/users/me`, {
      withCredentials: true, // to send cookies
    })

    // console.log('deleteMe response: ', response)
  } catch (e) {
    // console.log('deleteMe error: ', e)
    console.error(e)
    throw e
  }
}
